import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPowerBI } from 'api/PowerBIBase';
import { PowerBIReduxState } from './types';

const initialState: PowerBIReduxState = {
    actions: {
        getPowerBI: false,
    },
    powerBIToken: {
        token: {
            accessToken: '',
            embedUrl: '',
            reportId: '',
        },
    },
    error: {
        getPowerBI: '',
    },
};

const powerbiSlice = createSlice({
    name: 'powerbi',
    initialState,
    reducers: {
        getPowerBIAttempt: (state) => {
            state.actions.getPowerBI = true;
            state.error.getPowerBI = '';
        },
        getPowerBISuccess: (state, action: PayloadAction<IPowerBI>) => {
            state.actions.getPowerBI = false;

            if (action.payload) {
                state.powerBIToken = action.payload;
            }
        },
        getPowerBIFailure: (state, action: PayloadAction<string | undefined>) => {
            state.actions.getPowerBI = false;
            if (action.payload) {
                state.error.getPowerBI = action.payload;
            }
        },
    },
});

export type PowerBIState = typeof initialState;

export default {
    actions: powerbiSlice.actions,
    reducers: powerbiSlice.reducer,
};
