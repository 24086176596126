import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';

export interface IPowerBI {
    token: {
        accessToken: string;
        embedUrl: string;
        reportId: string;
    }
}

export abstract class IPowerBIGateway extends Gateway {
    abstract getEmbedToken(): GatewayResponse<IPowerBI>;
}
