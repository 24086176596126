import { put, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import Actions from 'redux/Actions';

import { GatewayResponseStatus } from 'api/types/types';
import PowerBIGateway from 'api/PowerBI';

export default function* watchGetPowerBIToken(api: PowerBIGateway): SagaWatcherReturnType {
    yield takeEvery('powerbi/getPowerBIAttempt', handleGetPowerBIToken, api);
}

function* handleGetPowerBIToken(api: PowerBIGateway) {
    const response = yield* call([api, api.getEmbedToken]);
    if (response.status === GatewayResponseStatus.Success) {
        yield put(Actions.getPowerBISuccess(response.data));
    }
    if (response.status === GatewayResponseStatus.Error) {
        yield put(Actions.getPowerBIFailure(response.message));
    }
}
