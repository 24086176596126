import React, { FunctionComponent, useEffect, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

import { AppDispatch, RootState } from 'redux/store';
import Selectors from 'redux/Selectors';
import { connect } from 'react-redux';
import { IPowerBI } from 'api/PowerBIBase';
import Actions from 'redux/Actions';
import './style.css';

interface PowerBIAnalyticsProps {
    powerbiError: string;
    powerbiData: IPowerBI;
    getPowerBI: () => void;
 }

const PowerBIAnalytics: FunctionComponent<PowerBIAnalyticsProps> = (props: PowerBIAnalyticsProps) => {
    const {
        powerbiError,
        powerbiData,
        getPowerBI,
    } = props;

    const [accessToken, setAccessToken] = useState<string>('');
    const [embedUrl, setEmbedUrl] = useState<string>('');
    const [reportId, setReportId] = useState<string>('');

    useEffect(() => {
        getPowerBI();
    }, []);

    useEffect(() => {
        if (powerbiData) {
            setAccessToken(powerbiData.token.accessToken);
            setEmbedUrl(powerbiData.token.embedUrl);
            setReportId(powerbiData.token.reportId);
        }
    }, [powerbiData]);

    if (powerbiError) {
        return (
            <div style={{ color: 'red', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <p>{powerbiError}</p>
            </div>
        );
    }

    return (
        <div className='w-100 h-100'>
            <PowerBIEmbed
                embedConfig={{
                    type: 'report', // Supported types: report, dashboard, tile, visual, qna, paginated report and create
                    id: reportId,
                    embedUrl,
                    accessToken,
                    tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
                    settings: {
                        panes: {
                            filters: {
                                expanded: false,
                                visible: false,
                            },
                            pageNavigation: {
                                visible: false,
                            },
                        },
                        background: models.BackgroundType.Default,
                    },
                }}
                cssClassName='powerbi-container'
            />
        </div>

    );
};

const mapStateToProps = (state: RootState) => ({
    powerbiError: Selectors.analyticsGetPowerBIError(state),
    powerbiData: Selectors.analyticsGetPowerBIData(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getPowerBI: () => dispatch(Actions.getPowerBIAttempt()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PowerBIAnalytics);
