import { ApiResponse } from 'apisauce';
import { GatewayResponse } from 'api/types/types';
import { IPowerBI, IPowerBIGateway } from './PowerBIBase';

export default class PowerBIGateway extends IPowerBIGateway {
    async getEmbedToken(): GatewayResponse<IPowerBI> {
        const response: ApiResponse<IPowerBI> = await this.api.get('/powerBi/embedInfo', {});
        return this.process(response);
    }
}
