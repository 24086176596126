import { IPowerBI } from 'api/PowerBIBase';
import { PowerBIState } from '.';

const getPowerBIAttempting = (state: PowerBIState): boolean => state.actions.getPowerBI || false;
const getPowerBIError = (state: PowerBIState): string => state.error.getPowerBI || '';
const getPowerBIData = (state: PowerBIState): IPowerBI => state.powerBIToken;

export default {
    getPowerBIAttempting,
    getPowerBIError,
    getPowerBIData,
};
