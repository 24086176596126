import { fork } from 'redux-saga/effects';

import PowerBIGateway from 'api/PowerBI';
import { RootSagaReturnType } from 'sagas/types';

import watchGetPowerBIToken from './getPowerBIToken';

export default (api: PowerBIGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(watchGetPowerBIToken, api);
    }
    return { rootSaga };
};
